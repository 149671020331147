<div class="flex h-screen items-center justify-center bg-gradient-to-r from-blue-200 via-blue-100 to-blue-200">
    <div class="transform rounded-2xl bg-white p-10 text-center shadow-2xl transition-all hover:scale-105">
        <mat-icon class="animate-pulse text-9xl text-indigo-500">error_outline</mat-icon>
        <h1 class="animate-wiggle mb-2 mt-6 text-6xl font-bold text-gray-800">404</h1>
        <h2 class="mb-4 mt-2 text-2xl font-bold text-gray-800">Oops! Page Not Found</h2>
        <p class="mb-8 text-gray-600">We can't seem to find the page you're looking for.</p>
        <button
            mat-raised-button
            color="primary"
            (click)="onGoHomeClick()"
            class="mt-4 rounded bg-indigo-500 px-4 py-2 font-bold text-white shadow-lg transition duration-300 ease-in-out hover:bg-indigo-600 hover:shadow-xl"
        >
            Go Home
        </button>
    </div>
</div>
