import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';

@Component({
    standalone: true,
    templateUrl: './not-found-page.component.html',
    imports: [MatIcon, MatButton, RouterLink],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {
    #router = inject(Router);

    public onGoHomeClick(): void {
        void this.#router.navigate(['/'], { replaceUrl: true });
    }
}
