import { provideHttpClient, withInterceptors, withRequestsMadeViaParent } from '@angular/common/http';
import { Provider } from '@angular/core';

import { SharedDataAccessProviders } from '@portal/shared/data-access';
import {
    jwtAuthInterceptor,
    provideLocalizations,
    provideLocalizedCurrencyPipe,
    tenantIdInterceptor,
    userAuthInterceptor,
} from '@portal/shared/utils';

export const authenticatedDomain: Provider = [
    SharedDataAccessProviders,
    provideLocalizedCurrencyPipe(),
    provideLocalizations(),
    provideHttpClient(withRequestsMadeViaParent(), withInterceptors([tenantIdInterceptor, jwtAuthInterceptor, userAuthInterceptor])),
];
